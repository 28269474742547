export function clickOutside(node, excludeId) {

    const handleClick = (event) => {
        if (!node.contains(event.target) && !event.target.closest('#'+excludeId)) {
            event.stopPropagation()
            node.dispatchEvent(new CustomEvent("outclick"));
        }
    };

    document.addEventListener("click", handleClick, true);

    return {
        destroy() {
            document.removeEventListener("click", handleClick, true);
        }
    };
}