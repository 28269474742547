<script>
    import {filterSettings} from "$lib/stores/filterSettings.js";
    import {filtered} from "$lib/stores/filterSettings_filtered.js";
</script>

<button on:click={filterSettings.resetWordsAndStatic} disabled={!$filtered}>reset filter ↺</button>

<style>
    button {
        width: 100%;
        margin-top: 1.2rem;
        margin-bottom: 0;
        padding: .5rem 0;
        font-weight: var(--font-emph);
        color: white;
        background: var(--color-accent);
        font-size: .9rem;
    }

    button:disabled {
        color: var(--grey-60);
        background: var(--color-light);
        cursor: default;
        box-shadow: none;
    }

    button:focus {
        box-shadow: none;
    }

    @media (max-width: 1000px) {
        button {
            background: var(--color-lighter);
            color: var(--color-accent);
            box-shadow: 0 0 0 2px var(--color-accent), 0 0 0 4px rgba(202, 139, 22, .5);
        }

        @media (prefers-color-scheme: dark) {
            button {
                color: var(--grey-95);
                border: none;
                box-shadow: 0 0 0 2px var(--color-dark-3), 0 0 0 4px rgba(202, 139, 22, .5);
            }
        }
    }
</style>