<!--suppress HtmlUnknownAttribute -->
<script>
    export let options = [],
        setName,
        value = "";

    let reducedOptions = options.filter(option => option !== 'Article')

</script>
<div class="setName">{setName}</div>
<div id="horizontal-radio-btns-word-class"
     data-testid="filter-for-word-class"
>
    <label class="first-label"
           class:selected={value === ''}
           style:background={value === '' ? `var(--color-accent)` : ''}
           style:border-color={value === '' ? `var(--color-accent)` : ''}
    >
        All
        <input type="radio" name="{setName}" value="" bind:group={value}>
    </label>
    {#each reducedOptions as option}
        <label class:selected={value === option}
               style:background={value === option ? `var(--color-${option})` : ''}
               style:border-color={value === option ? `var(--color-${option})` : ''}
        >
            {option === 'Num' ? 'Number' : option}
            <input type="radio" name="{setName}" value={option} bind:group={value}>
        </label>
    {/each}
</div>

<style>
    .setName {
        margin-top: var(--margin-big);
        margin-bottom: var(--margin-medium);
        font-size: .9rem;
        font-weight: var(--font-emph);
    }

    #horizontal-radio-btns-word-class {
        display: flex;
        flex-wrap: wrap;
    }

    label:first-of-type {
        border-top-left-radius: var(--border-radius);
        /*font-weight: normal;*/
    }

    label:nth-of-type(3) {
        border-top-right-radius: var(--border-radius);
    }

    label:nth-of-type(9) {
        border-bottom-left-radius: var(--border-radius);
    }

    label:last-of-type {
        border-bottom-right-radius: var(--border-radius);
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        appearance: none;
        z-index: -1;
    }

    label {
        flex-grow: 1;
        background: var(--color-light);
        transition: background 200ms, border 200ms;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .95rem;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        padding: .3rem .23rem;
        border: 1px solid var(--color-dark);
        /*background: transparent;*/
    }

    .selected {
        font-weight: var(--font-emph);
        color: var(--color-light);
        /*border-color: var(--color-accent);*/
        /*background: var(--color-accent);*/
    }

    @media (prefers-color-scheme: dark) {
        .selected {
            color: var(--color-dark-2);
        }
    }

</style>