<!--suppress UnnecessaryLabelJS -->
<script>
    import '$lib/styles/styles.css';
    import {pageWidth} from "$lib/stores/ui/pageWidth.js";
    import {userSettings} from "$lib/stores/userSettings.js";
    import NavLinks from "$lib/components/_page/NavLinks.svelte";
    import Filter from "$lib/components/browse/Filter.svelte";
    import LogoLoginSettings from "$lib/components/_page/LogoLoginSettings.svelte";
    import {device} from "$lib/stores/ui/device.js";
    import FilterMobile from "$lib/components/browse/FilterMobile.svelte";
    import {runInitRoutine} from "$lib/routines/routines_init.js";
    import {hiddenFn} from "$lib/utils/hiddenFn.js";
    import {beforeNavigate} from "$app/navigation";
    import {page, updated} from "$app/stores";
    import {remoteChanges} from "$lib/stores/ui/remoteChanges.js";
    import RemoteChanges from "$lib/components/_page/RemoteChanges.svelte";
    import {onDestroy} from "svelte";
    import {userWordsMap} from "$lib/stores/userWordsMap.js";
    import {proVersion} from "$lib/stores/proVersion.js";
    import {logInProduction} from "$lib/utils/logInProduction.js";
    import {browser, version} from "$app/environment";

    function handleKeydown(event) {
        if (event.code === 'KeyH' && event.altKey && event.metaKey) hiddenFn()
    }

    beforeNavigate(async navigation => {
        await updated.check()
        // NOTE $updated doesn't seem to be checked in dev mode !!!
        // NOTE $updated needs to be used with $ syntax so there's a subscriber to the store
        // NOTE navigation.to might be null - I think that's the case after window.location.href is set and callback runs once more
        if($updated) {
           logInProduction(`$updated >>> navigation\n ${JSON.stringify(navigation, null, 2)}`)
        }
        if ($updated && navigation.to) {
            logInProduction(`beforeNavigate - ${version} - $updated && navigation.to `)
            const navigatingTo = navigation.to.url.href
            navigation.cancel()
            logInProduction('navigating to ' + navigatingTo)
            window.location.href = navigatingTo
        }
    })

    onDestroy(() => {
        if (userWordsMap.cancelCollectionSubscription) userWordsMap.cancelCollectionSubscription()
        if (proVersion.cancelCollectionSubscription) proVersion.cancelCollectionSubscription()
    })

    const dev = import.meta.env.DEV
    const prod = import.meta.env.PROD
</script>

<svelte:head>
    {#if dev}
        <!-- prevent staging from being indexed by (google) bot -->
        <meta name="robots" content="noindex">
    {/if}

    {#if browser}
        <!-- NOTE Tawk yOffset stopped working with initialization in hooks.client.js -->
        {@const allScriptNotDisabled = localStorage.getItem('disableScripts') === null}
        {@const tawkNotDisabled = localStorage.getItem('disableTawk') === null}
        {@const tawkEnabled = localStorage.getItem('enableTawk') !== null}
        {#if tawkEnabled || (prod && allScriptNotDisabled && tawkNotDisabled)}
            <script src="/tawk.js"></script>
        {/if}
    {/if}
</svelte:head>

<svelte:window on:keydown={handleKeydown}/>

{#if $remoteChanges}

    <RemoteChanges/>

{:else}

    <div id="page"
         bind:clientWidth={$pageWidth}
         style="--selected-color:{$userSettings.selectedColor};
            --color-maskulinum:{$userSettings.nounColors.maskulinum};
            --color-femininum:{$userSettings.nounColors.femininum};
            --color-neutrum:{$userSettings.nounColors.neutrum};"
    >
        <LogoLoginSettings/>

        <NavLinks/>

        {#await runInitRoutine then _}
            {#if $page.url.pathname.startsWith('/browse') && $device === 'desktop'}
                <div style="grid-area: filter;">
                    <Filter/>
                </div>
            {/if}
        {/await}

        <main>

            {#await runInitRoutine then _}
                {#if $page.url.pathname.startsWith('/browse') && $device === 'mobile'}
                    <FilterMobile/>
                {/if}
            {/await}

            <slot/>
        </main>
    </div>

{/if}

<style>
    #page {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1.2rem 230px 1.2rem 1fr;
        grid-template-rows: min-content min-content 1fr;
        grid-template-areas:
                ". logo   . main"
                ". nav    . main"
                ". filter . main";
    }

    main {
        position: relative;
        height: 100%;
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 1000px) {
        #page {
            grid-template-columns: 100%;
            --logo-height: 3.3rem;
            --nav-height: 3.3rem;
            grid-template-rows: var(--logo-height) calc(100% - var(--logo-height) - var(--nav-height)) var(--nav-height);
            grid-template-areas:
                    "logo"
                    "main"
                    "nav";
        }
    }

</style>