import {PaymentService} from "$lib/services/payment-service.js";
import {loggedIn} from "$lib/stores/loggedIn.js";
import {subscription} from "$lib/services/subscription.js";
import {proVersion} from "$lib/stores/proVersion.js";
import {AUTH as auth} from "$lib/services/auth.js";
import {browser} from "$app/environment";

export const prerender = true
export const ssr = false

export async function load({url}) {
    // TODO wrap everything in (app) layout group and place an +error.svelte page outside
    //  so that errors happing here in layout.js will lead to that page

    if (browser && url.searchParams.has('ref')) {
        const refCode = url.searchParams.get('ref')
        localStorage.setItem('ref', refCode)
    }

    // const subscription = new Subscription(DATABASE)
    // subscription.connect()

    const paymentService = new PaymentService()

    // TODO refactor !!!
    // function onLogin(user) {
    //     subscription.connect(user.uid)
    // }
    //

    function onLogout() {
        // QUESTION here might be the right place to handle rerouting for protected routes..?!?
        // NOTE while the rerouting would be handled on auth state change, it wouldn't when accessing the route otherwise..?!?
        // First reroute
        // switch (url.pathname) {
        //     case '/settings':
        //     case '/subscription':
        //     case '/stats':
        //         // goto('/signin', {state: ...})
        //         console.log('on route >>', url.pathname)
        //         break
        // }
        // Then disconnect all services
        // TODO handle error
        // userSettings.disconnect()
        // userWordsMap.disconnect()
        // reviewCount.disconnect()
        // proVersion.disconnect()
        // subscription.disconnect()
    }

    // const auth = new Auth(onLogin, onLogout)

    return {
        auth: auth,
        loggedIn: loggedIn,
        paymentService: paymentService,
        proVersion: proVersion,
        subscription: subscription,
    }
}
