import {initializePaddle} from "@paddle/paddle-js";
import {PADDLE_CHECKOUT_CONTAINER_CLASS} from "../../consts.js";
import {getIpAddress} from "$lib/helper-fns/getIpAddress.js";

// TODO
// QUESTION would it be clearer to open/close paymentService with login/logout and pass uid/email there..?!?
//  argument against: paymentService is only needed for pricing/subscription route

export class PaymentService {
    #PaddleInstancePromise = null

    constructor() {}

    #getPaddleInstance = async () => {
        if (this.#PaddleInstancePromise === null) {
            try {
                this.#PaddleInstancePromise = await initializePaddle({
                    environment: import.meta.env.DEV ? 'sandbox' : 'production',
                    token: import.meta.env.VITE_PADDLE_CLIENT_SIDE_TOKEN,
                    checkout: {
                        settings: {
                            displayMode: 'overlay',
                            frameTarget: PADDLE_CHECKOUT_CONTAINER_CLASS,
                            frameInitialHeight: 500,
                            frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
                            showAddDiscounts: false,
                            allowLogout: false,
                        }
                    },
                    // eventCallback: function (data) {
                    //     switch (data.name) {
                    //         case "checkout.closed":
                    //             break;
                    //         default:
                    //             console.log('Paddle eventCallback', data.name)
                    //     }
                    // }
                })
            } catch (error) {
                error.message = 'ERROR initializing Paddle - ' + error.message
                throw error
            }
        }
        if (this.#PaddleInstancePromise === undefined) {
            throw new Error('ERROR initializing Paddle - #PaddleInstancePromise is undefined')
            // Should only happen server-side when !window
        }
        return this.#PaddleInstancePromise
    }

    async init() {
        await this.#getPaddleInstance()
    }

    async getPrices() {
        const paddleInstance = await this.#getPaddleInstance()
        const ipAddress = await getIpAddress()
        const request = {
            customerIpAddress: ipAddress,
            items: [
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_SILVER_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_GOLD_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_PLATINUM_PRICE_ID,
                }
            ]
        }

        const result = await paddleInstance.PricePreview(request)
        return result.data.details.lineItems.map(lineItem => {
            return {
                price: lineItem.formattedUnitTotals.total,
                total: lineItem.unitTotals.total,
                // name: lineItem.price.name,
                // description: lineItem.price.description,
            }
        })
    }

    async updateCheckout(eventCallback) {
        const paddleInstance = await this.#getPaddleInstance()
        paddleInstance.Update({
            eventCallback: eventCallback
        })
    }

    // options: {email, uid}
    async openCheckout(options) {
        const uid = options.uid
        const email = options.email
        if (uid === undefined || email === undefined) {
            console.error('ERROR openCheckout - uid or vocabeoEmail is undefined')
            return
        }
        const paddleInstance = await this.#getPaddleInstance()

        paddleInstance.Checkout.open({
            settings: {
                variant: 'one-page',
                displayMode: options.displayMode,
            },
            customer: {
                email: email
            },
            items: options.transactionId ? null : [{
                quantity: 1,
                priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
            }],
            customData: {
                uid: uid,
                vocabeoEmail: email,
            },
            transactionId: options.transactionId,
        })
    }
}