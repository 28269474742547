<!--suppress HtmlUnknownTag, CssInvalidHtmlTagReference -->
<script>
    import {fade} from 'svelte/transition';
    import {filterSettings} from "../../stores/filterSettings";
    import HorizontalRadioBtns from "./Filter/HorizontalRadioBtns.svelte";
    import AllWordsUserWords from "./Filter/AllWordsUserWords.svelte";
    import HorizontalRadioBtnsWordClass from "./Filter/HorizontalRadioBtnsWordClass.svelte";
    import {filterOptions} from "../../data/filterOptions.js";
    import {displayWords} from "../../stores/displayWords";
    import {userWordsMap} from "$lib/stores/userWordsMap.js";
    import {onDestroy} from "svelte";
    import Search from "$lib/components/browse/Filter/Search.svelte";
    import ResetFilterButton from "$lib/components/browse/Filter/ResetFilterButton.svelte";

    onDestroy(() => {
        unsubFilterSettings()
        filterSettings.resetAll()
    })

    const unsubFilterSettings = filterSettings.subscribe(filterSettings => {
        displayWords.filter(filterSettings, $userWordsMap)
    });

</script>

<div id="filter-wrapper"
     in:fade|global={{duration: 400}}
     out:fade|global={{duration: 200}}
>

    <Search/>

    <HorizontalRadioBtns options={['alphabetically']} setName="Sort"
                         testId="sort-alphabetically"
                         bind:value={$filterSettings.sortAlph}
                         firstValue="by frequency"
                         fontSize=".9rem"
                         maxHeight="1.7rem"
    />

    <AllWordsUserWords bind:value={$filterSettings.words}/>

    <HorizontalRadioBtnsWordClass options={filterOptions.art} setName="Word class" bind:value={$filterSettings.art}/>

    <HorizontalRadioBtns options={filterOptions.level.concat('-')} setName="Level" bind:value={$filterSettings.level}
                         testId="filter-for-level"
    >
        <!--        <Info/>-->
    </HorizontalRadioBtns>

    <!--    <HorizontalRadioBtns options={$filterOptions.frequency} setName="Frequency" bind:value={$filterSettings.frequency}/>-->

    <!--    <HorizontalRadioBtns options={$filterOptions.syllables} setName="Syllables" bind:value={$filterSettings.syllables}/>-->

    <ResetFilterButton/>

</div>

<style>

    #filter-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        /*because of Goethe popup*/
        /*overflow: visible;*/
    }

</style>