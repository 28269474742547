// noinspection ES6UnusedImports

import {get} from 'svelte/store'
import {version} from '$app/environment';
import {logInProduction} from "$lib/utils/logInProduction.js";
import {allWords} from "$lib/data/allWords.js";
import {wordIdToSentences} from "$lib/data/wordIdToSentences.js";

// shortcut >>>  Alt + Apfel + H
export async function hiddenFn() {

    // add dev: label, wenn heikel in production !!!

    console.log('hidden')
    logInProduction('version > ' + version)

    const all = document.querySelectorAll('*').length
    const inList = document.querySelector('#list').querySelectorAll('*').length
    const inCard = document.querySelector('#card').querySelectorAll('*').length

    console.log('all', all, 'list', inList, 'card', inCard)

}